<template>
  <v-container>
    <breadcrumbs class="mb-4" :items="breadcrumbs"></breadcrumbs>
    <v-row>
      <v-col xl="7" lg="6" md="12" sm="12" xs="12">
        <v-sheet class="pa-8 mb-4">
          <h1>{{ $t("support-title") }}</h1>
          <p v-for="paragraph in $t('support-text')" :key="paragraph">
            {{ paragraph }}
          </p>
          <v-row justify="center" no-gutters>
            <v-col>
              <v-btn color="accent" to="/contact">{{
                $t("support-button-feedback")
              }}</v-btn>
            </v-col>
            <v-col>
              <v-btn
                v-if="$vuetify.breakpoint.xsOnly"
                color="accent"
                to="/association/membership"
                >{{ $t("support-button-membership-alt") }}</v-btn
              >
              <v-btn v-else color="accent" to="/association/membership">{{
                $t("support-button-membership")
              }}</v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
      <v-col>
        <v-card color="accent" class="pa-8" elevation="15">
          <p class="display-1 text-up font-weight-bold">
            {{ $t("support-account-title") }}
          </p>
          <p class="title">
            {{ $t("support-account-receiver") }} {{ payment_receiver }}
          </p>
          <p class="title">IBAN: {{ payment_iban }}</p>
          <p class="title">BIC: {{ payment_bic }}</p>
          <p class="subtitle-1 font-weight-bold">
            {{ $t("support-account-reason-1") }}
          </p>
          <p class="subtitle-1 font-weight-bold">
            {{ $t("support-account-reason-2") }}
          </p>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  name: "support",
  components: {
    Breadcrumbs
  },
  data: () => ({
    payment_receiver: "Bauchgefühle Gießen e.V.",
    payment_iban: "DE74513900000077441603",
    payment_bic: "VBMHDE5F"
  }),
  computed: {
    breadcrumbs: function() {
      return [
        {
          text: this.$t("home"),
          disabled: false,
          href: "/"
        },
        {
          text: this.$t("association"),
          disabled: false,
          href: "/association"
        },
        {
          text: this.$t("support"),
          disabled: true
        }
      ];
    }
  }
};
</script>
<style scoped>
p {
  text-align: justify;
}
</style>
